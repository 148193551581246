import { defineStore } from "pinia"

/** 活动相关 */
type ActivityState = {
  /**
   * preHint: 活動啟動前展示開關
   * liveWidget: 直播展示
   * liveLink: 直播地址
   * facebookLink: 直播地址
   * tiktokLink: 直播地址
   * youtubeLink: 直播地址
   * igLink: 直播地址
   * bigoLink: 直播地址
   * kumoLink: 直播地址
   * invisible: 充值列表將要在活動啟動前隱藏的活動 ID
   *
   */
  testflight: {
    preHint: boolean
    liveWidget: boolean
    liveLink: string
    facebookLink: string
    tiktokLink: string
    youtubeLink: string
    igLink: string
    bigoLink: string
    kumoLink: string
    invisible: string[]
  } | null
}

export const useActivityStore = defineStore("ActivityStore", {
  state: (): ActivityState => ({
    testflight: null as ActivityState["testflight"],
  }),
  getters: {},
  actions: {
    SET_TESTFLIGHT(data: ActivityState["testflight"]) {
      if (data) {
        this.testflight = data
      }
    },
  },
})
